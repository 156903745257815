import React from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom';
import Navbar from './component/Navbar';
import Home from './component/Home';
import About from './component/About';
import Rooms from './component/Rooms';
// import Faqs from './component/Faqs';
// import Doctors from './component/Doctors';
// import Franchise from './component/Franchise';
import Contact from './component/Contact';
import Services from './component/Services';
import WhatsappIcon from './Pages/WhatsappIcon';

const App = () => {
  return (
    <div>
        <HashRouter>
          <WhatsappIcon/>
    <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/> 
         <Route path='/About' element={<About/>}/>
         <Route path='/Rooms' element={<Rooms/>} />
         <Route path='/Services' element={<Services/>} />
         <Route path='Contact' element={<Contact/>} /> 
      </Routes>
    </HashRouter>
    </div>
  )
}

export default App
