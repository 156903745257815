import event1 from "../images/Event/0e52841f-2e04-40b6-9bba-3d21bb760ec7.avif"
import event2 from "../images/Event/2a704080-1b26-4a59-90aa-108625e02df7.avif"
import event3 from "../images/Event/lord-ganesha.avif"

import event4 from "../images/Event/flower.avif"
import event5 from "../images/event-raj-5.webp"
import event6 from "../images/event-raj-6.png"

import event8 from "../images/event-raj-9.webp"
import event9 from "../images/event-raj-10.webp"
// import event10 from "../images/event-raj-11.webp"
// import event11 from "../images/event-raj-12.webp"
import event12 from "../images/ab2d3c63-71ae-4771-a7e9-1fd22770321d.jpg"
import event13 from "../images/event-raj-14.png"

import birthday1 from "../images/Birthday/7cb3e051-ee53-42bc-9051-99e837622ccd.avif"
import birthday2 from "../images/event-raj-14.png"

import room1 from "../images/Room/raj-room.avif"
import room2 from "../images/Room/0a47bccf-b9bc-48be-b75d-fb92c4374d63.avif"
import weeding1 from "../images/weding/9aa95662-362e-438c-8da7-6f6a63e3a1c6-_1_.avif"
import weeding2 from "../images/weding/58c8fcb5-4464-438b-bf21-5d3f52a8f6b9 (2).avif"
import weeding3 from "../images/weding/67cc4cfc-1107-4a4b-8420-a1aa8b0c4673.avif"
import weeding4 from "../images/weding/weeding.avif"
import weeding5 from "../images/weding/wedding2.avif"
import weeding6 from "../images/weding/weeding4.avif"


const Rajresort =[
    {
        id:1,
        title: "Events",
        category:"events",
        imageUr1: event1,
        width: 3,
        height: 4,
        
      },
      {
        id:2,
        title: "Events",
        category:"events",
        imageUr1: event2,
        width: 4,
        height: 3,
      
      },
      {
        id:3,
        title: "Events",
        category:"events",
        imageUr1: event3,
        width: 2,
        height: 2,
      },
      {
        id:4,
        title: "Events",
        category:"events",
        imageUr1:event4,
        width: 4,
        height: 4,
      },
      {
        id:5,
        title: "Events",
        category:"events",
        imageUr1:event5,
        width: 3,
        height: 4,
      },
      {
        id:6,
        title: "Events",
        category:"events",
        imageUr1:event6,
        width: 4,
        height: 3,
      },
      
      {
        id:8,
        title: "Events",
        category:"events",
        imageUr1:event8,
        width: 2,
        height: 3,
      },
      {
        id:9,
        title: "Events",
        category:"events",
        imageUr1:event9,
        width: 3,
        height: 3,
      },
      // {
      //   id:10,
      //   title: "Events",
      //   category:"events",
      //   imageUr1:event10,
      //   width: 4,
      //   height: 4,
      // },
      // {
      //   id:11,
      //   title: "Events",
      //   category:"events",
      //   imageUr1:event11,
      //   width: 3,
      //   height: 4,
      // },
      // {
      //   id:12,
      //   title: "Events",
      //   category:"events",
      //   imageUr1:event12,
      //   width: 4,
      //   height: 3,
      // },
      {
        id:13,
        title: "Events",
        category:"events",
        imageUr1:event13,
        width: 2,
        height: 2,
      },
      // {
      //   id:14,
      //   title: "Events",
      //   category:"events",
      //   imageUr1:event14,
      //   width: 3,
      //   height: 3,
      // },
      {
        id:1,
        title: "Birthday",
        category:"birthday",
        imageUr1: birthday1,
        width: 1,
        height: 1,
      },

      {
        id:2,
        title: "Birthday",
        category:"birthday",
        imageUr1:birthday2,
        width: 3,
        height: 4,
      },

      
      {
        id: 1,
        title: "A/C Room",
        category:"room",
        imageUr1: room1,
        width: 4,
       height: 3,
      },
      {
        id: 2,
        title: "A/C Room",
        category:"room",
        imageUr1: room2,
        width: 4,
       height: 3,
      },

      {
        id:1,
        title: "Weeding",
        category:"wedding",
        imageUr1: weeding1,
        width: 4,
        height: 3,
      },
      {
        id:2,
        title: "Weeding",
        category:"wedding",
        imageUr1: weeding2,
        width: 1,
        height: 1,
      },
      {
        id:3,
        title: "Weeding",
        category:"wedding",
        imageUr1: weeding3,
        width: 1,
        height: 1,
      },
      {
        id:4,
        title: "Weeding",
        category:"wedding",
        imageUr1: weeding4,
        width: 1,
        height: 1,
      },
      {
        id:5,
        title: "Weeding",
        category:"wedding",
        imageUr1: weeding5,
        width: 1,
        height: 1,
      },
      {
        id:6,
        title: "Weeding",
        category:"wedding",
        imageUr1: weeding6,
        width: 1,
        height: 1,
      },
]

export default Rajresort